<template>

  <div class="page-container">

    <router-link to="/">
      <img class="logo" src="@/assets/images/esprezzo-logo.png" />
    </router-link>

    <div ref="containerElement" class="container">

      <LoadingOverlay v-if="isLoading" />

      <h4>Sign in to Dispatch</h4>

      <form @submit="submit">
        <FormSubmitErrors :formName="formName" />
        <FormInput :formName="formName" fieldName="email" ref="focusElement" />
        <FormInput :formName="formName" fieldName="password" />
        <FormCheckbox :formName="formName" fieldName="keepMeLoggedIn" />
        <button type="submit" :disabled="isLoading || !isFormValid">Login</button>
        <p class="after-button-note">
          <router-link to="/forgot-password" class="subtle">Forgot your password?</router-link>
        </p>
      </form>

    </div>

    <p class="post-content-footer">
      Don't have an account?
      <router-link to="/signup">Request one.</router-link>
    </p>

    <p class="recaptcha-note">
      Invisible reCAPTCHA by Google
      <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a>.
    </p>

  </div>

</template>

<script>

  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'
  import useRecaptcha from '@/composables/useRecaptcha'

  import FormInput from '@/components/forms/FormInput.vue'
  import FormCheckbox from '@/components/forms/FormCheckbox.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      FormInput,
      FormCheckbox,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const formName = 'loginForm'

      // composables
      const { setRecaptchaToken } = useRecaptcha({ formName, action: 'login' })
      const { form, isFormValid, focusElement, scrollToFormTop, containerElement } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
        scrollToFormTop,
        containerElement,
        setRecaptchaToken,
      }

    },
    computed: {
      ...mapState('user', ['user']),
    },
    data() {
      return {
        // @NOTE: we don't simply use $store.state.forms[formName].isLoading
        //  because that resets to false after submitting and we need the
        //  loading overlay to still show while the app/AFTER_LOGIN action runs
        isLoading: false,
        isProduction: process.env.VUE_APP_ENV === 'production',
      }
    },
    methods: {
      submit($event) {

        if ($event) $event.preventDefault()

        this.isLoading = true

        this.setRecaptchaToken()
          .then(() => {

            return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
              .then((response) => {

                const now = Date.now()

                const newAuthTokenData = {
                  newAuthToken: response.data.accessToken,
                  newAuthTokenExpiry: response.data.expiresAt,
                }

                // only save the refresh token if they chose those the "keep me
                //  logged in" option
                //
                // @NOTE: 1209600000 = 2 weeks in milliseconds
                if (this.form.fields.keepMeLoggedIn.value) {
                  newAuthTokenData.newRefreshToken = response.data.refreshToken
                  newAuthTokenData.newRefreshTokenExpiry = new Date(now + 1209600000).toISOString()
                }

                return this.$store.dispatch('user/UPDATE_AUTH_TOKEN', newAuthTokenData)
                  .then(() => {
                    return this.$store.dispatch('app/AFTER_LOGIN')
                  })
                  .then(() => {

                    if (this.isProduction) return

                    const accountSwitcherUsers = JSON.parse(window.localStorage.getItem('dispatch.accountSwitcherUsers') || '[]')

                    const existingAccountSwitcherUserIndex = accountSwitcherUsers.findIndex((accountSwitcherUser) => {
                      return accountSwitcherUser.username === this.user.username
                    })

                    if (existingAccountSwitcherUserIndex !== -1) {
                      accountSwitcherUsers.splice(existingAccountSwitcherUserIndex, 1)
                    }

                    accountSwitcherUsers.unshift({
                      email: this.user.email,
                      username: this.user.username,
                      password: this.form.fields.password.value,
                    })

                    window.localStorage.setItem('dispatch.accountSwitcherUsers', JSON.stringify(accountSwitcherUsers))

                  })
                  .then(() => {

                    this.$mixpanel.onReady((mixpanel) => {
                      mixpanel.track('Explicitly Logged In')
                    })

                    this.$router.push(this.$route.params.destination || '/dashboard')

                  })

              })
              .catch(() => {
                // do nothing
              })

          })
          .finally(() => {
            this.scrollToFormTop()
            this.isLoading = false
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/logged-out-page.styl'

</style>
